<template>
  <div>
    <div
      class="common-wrap"
      :class="$i18n.locale == 'en' ? 'en-home-wrap' : ''"
    >
      <headNav active="4"></headNav>
      <div class="common-contents">
        <div class="banner">
          <img
            src="@/assets/version2/other-26.png"
            alt=""
            srcset=""
            class="img"
          />
        </div>
        <div class="contents">
          <div class="main">
            <!-- 中心简介 -->
            <div>
              <main-title
                :name="$t('training.centre')"
                width="205"
                height="43"
                :src="require('../assets/version2/peixun-1.png')"
              ></main-title>
              <div class="mt32 com-text tal">
                {{ $t("training.introduc") }}
              </div>
            </div>
            <!-- 教师风采 -->
            <div class="main-item">
              <div class="dot dot-1"></div>
              <div class="dot img-1"></div>
              <div class="dot img-2"></div>
              <div class="dot dot-2"></div>
              <main-title
                style="margin-top: 200px"
                :name="$t('training.teacher')"
                width="118"
                height="33"
                :src="require('../assets/version2/peixun-2.png')"
              ></main-title>
              <div class="w1200 tal block flex between wrap">
                <div
                  class="teacher-item"
                  v-for="(item, index) in 18"
                  :key="index"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('../assets/version2/teacher-' + index + '.png') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
            <!-- 课程设置 -->
            <div class="main-item">
              <div class="dot dot-3"></div>
              <main-title
                style="margin-top: 200px"
                :name="$t('training.curriculum')"
                width="158"
                height="33"
                :src="require('../assets/version2/peixun-10.png')"
              ></main-title>
              <div class="w1200 block-4 tal">
                <div
                  class="instruments-item-list flex between wrap"
                  v-if="instrumentsList.length"
                >
                  <div
                    class="item"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('../assets/version2/instruments-' +
                          index +
                          '.png') +
                        ')',
                    }"
                    v-for="(item, index) in instrumentsList"
                    :key="index"
                  >
                    <div class="item-text" :class="`item-text-${index}`">
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 收费标准 -->
            <div class="main-item">
              <div class="dot dot-4"></div>
              <main-title
                style="margin-top: 200px"
                :name="$t('training.Fees')"
                width="256"
                height="44"
                :src="require('../assets/version2/peixun-25.png')"
              ></main-title>
              <div class="w1200 block-3">
                <div class="charge"></div>
              </div>
            </div>
            <!-- 扫码约课 -->
            <div class="main-item">
              <div class="dot dot-5"></div>
              <main-title
                style="margin-top: 200px"
                :name="$t('training.smyk')"
                width="233"
                height="33"
                :src="require('../assets/version2/peixun-26.png')"
              ></main-title>
              <div class="w1200 block-2">
                <div class="f-w-3 f-16 t-c2 tal">
                  <div>咨询 答疑 约课</div>
                  <div>请用微信扫二维码添加苏州民族管弦乐团培训中心</div>
                </div>
                <div class="qrcode mt-30" id="test"></div>
              </div>
            </div>
            <!-- 社会艺术水平考级 -->
            <div class="main-item">
              <main-title
                style="margin-top: 200px"
                :name="$t('training.socialArt')"
                width="176"
                height="34"
                :src="require('../assets/version2/peixun-18.png')"
              ></main-title>
              <div class="w1200 block-5 flex between wrap">
                <div
                  class="item item-1"
                  @click="testRegistration('phone', false)"
                >
                  <div class="img"></div>
                  <div class="text">{{ $t("training.examination") }}</div>
                </div>
                <div
                  class="item item-2"
                  @click="testRegistration('test', true)"
                >
                  <div class="img"></div>
                  <div class="text">{{ $t("training.info") }}</div>
                </div>
                <div
                  class="item item-3"
                  @click="testRegistration('testScoreInquiry', false)"
                >
                  <div class="img"></div>
                  <div class="text" id="teenager">
                    {{ $t("training.info4") }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 青少年大赛 -->
            <div class="main-item">
              <main-title
                style="margin-top: 200px"
                :name="$t('training.showEvent')"
                width="351"
                height="43"
                :src="require('../assets/version2/peixun-17.png')"
              ></main-title>
              <div class="w1200 block-5 block-6 flex between wrap">
                <div
                  class="item item-1"
                  @click="testRegistration('phone', false)"
                >
                  <div class="img"></div>
                  <div class="text">{{ $t("training.examination") }}</div>
                </div>
                <div
                  class="item item-2"
                  @click="testRegistration('teenager', true)"
                >
                  <div class="img"></div>
                  <div class="text">{{ $t("training.info") }}</div>
                </div>
                <div
                  class="item item-3"
                  @click="testRegistration('teenagerScoreInquiry', true)"
                >
                  <div class="img"></div>
                  <div class="text">{{ $t("training.info3") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footNav />
    </div>
    <!-- 回到顶部 -->
    <a-back-top />
    <el-dialog :visible.sync="signUpDialogVisible" width="30%">
      <div align="center" class="mb-20 f-18">请使用微信扫码报名</div>
      <img
        src="@/assets/qrcode.png"
        alt=""
        srcset=""
        style="width: 200px; height: 200px"
        class="mb-30"
      />
    </el-dialog>
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import mainTitle from "../components/main-title/index.vue";
export default {
  components: { headNav, footNav, mainTitle },
  data() {
    return {
      instrumentsList: [],
      signUpDialogVisible: false,
    };
  },
  watch: {
    $route(newValue) {
      this.$nextTick(() => {
        if (newValue.query.type == 1) {
          document.body.scrollTop = 0;
        } else if (newValue.query.type == 2) {
          document.getElementById("test").scrollIntoView();
        } else if (newValue.query.type == 3) {
          document.getElementById("teenager").scrollIntoView();
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.instrumentsList = [
        this.$t("training.profession1"),
        this.$t("training.profession2"),
        this.$t("training.profession3"),
        this.$t("training.profession7"),
        this.$t("training.profession8"),
        this.$t("training.profession9"),
        this.$t("training.profession4"),
        this.$t("training.profession5"),
        this.$t("training.profession6"),
        this.$t("training.profession10"),
      ];
      if (this.$route.query.type == 1) {
        document.body.scrollTop = 0;
      } else if (this.$route.query.type == 2) {
        document.getElementById("test").scrollIntoView();
      } else if (this.$route.query.type == 3) {
        document.getElementById("teenager").scrollIntoView();
      }
    });
  },
  methods: {
    // 考试报名
    testRegistration() {
      this.$alert("已结束或尚未开始", {
            confirmButtonText: "确定",
          });
    },
    // 考试报名
    // testRegistration(name, isOpen) {
    //   if (isOpen) {
    //     this.$router.push({ name: "testSearch", query: { type: name } });
    //   } else {
    //     if (name == "phone") {
    //       this.signUpDialogVisible = true;
    //     } else {
    //       this.$alert("已结束或尚未开始", {
    //         confirmButtonText: "确定",
    //       });
    //     }
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #f2f6f7;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .contents {
    .main {
      padding-bottom: 324px;
      .main-item {
        position: relative;
        .dot {
          position: absolute;
          z-index: 99;
          background-size: 100% 100%;
        }
        .dot-1 {
          width: 139px;
          height: 392px;
          top: -250px;
          right: 0;
          background-image: url("../assets/version2/peixun-27.png");
        }
        .dot-2 {
          width: 333px;
          height: 497px;
          top: 800px;
          right: 0;
          background-image: url("../assets/version2/show-bg-2.png");
        }
        .dot-3 {
          width: 256px;
          height: 646px;
          top: -300px;
          right: 0;
          background-image: url("../assets/version2/peixun-27.png");
        }
        .dot-4 {
          width: 309px;
          height: 545px;
          top: 150px;
          left: 0;
          transform: rotateY(180deg);
          background-image: url("../assets/version2/show-bg-2.png");
        }
        .dot-5 {
          width: 349px;
          height: 545px;
          top: -100px;
          right: 0;
          background-image: url("../assets/version2/show-bg-2.png");
        }
        .img-1 {
          width: 225px;
          height: 291px;
          top: -200px;
          right: 100px;
          background-image: url("../assets/version2/other-16.png");
        }
        .img-2 {
          width: 411px;
          height: 90px;
          top: 0;
          right: 371px;
          background-image: url("../assets/version2/peixun-9.png");
        }
      }
      .com-text {
        font-size: 16px;
        font-weight: 300;
        color: #333333;
        line-height: 28px;
        width: 1371px;
        margin: 0 auto;
        padding-left: 90px;
      }
      // 教师风采
      .block {
        padding-top: 25px;
        padding-left: 70px;
        .teacher-item {
          width: 351px;
          height: 268px;
          background-size: 100% 100%;
          margin-top: 32px;
        }
      }
      // 课程设置
      .block-4 {
        padding-left: 30px;
        .instruments-item-list {
          position: relative;
          .item {
            margin-top: 50px;
            width: 236px;
            height: 236px;
            background-size: 100% 100%;
            position: relative;
            z-index: 1;
            .item-text {
              display: flex;
              align-items: center;
              width: 50px;
              height: auto;
              color: #fff;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              font-size: 52px;
              line-height: 64px;
              text-align: center;
            }
          }
          &::after {
            content: "";
            width: 540px;
          }
        }
      }
      // 收费标准
      .block-3 {
        padding: 42px 0 0 30px;
        .charge {
          background-image: url("../assets/version2/peixun-21.png");
          background-size: 100% 100%;
          height: 824px;
        }
      }
      // 扫码约课
      .block-2 {
        padding: 42px 0 0 30px;
        .qrcode {
          background-image: url("../assets/version2/peixun-22.png");
          background-size: 100% 100%;
          width: 229px;
          height: 229px;
        }
      }
      // 考试
      .block-5 {
        padding: 42px 0 0 30px;
        .item {
          width: 340px;
          height: 340px;
          background-size: 100% 100%;
          background-color: #2b170d;
          border-radius: 80px 0 80px 0;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s linear 0s;
          background-image: url("../assets/version2/peixun-23.png");
          .text {
            color: #ffffff;
            font-weight: bold;
            font-size: 32px;
            margin-top: 20px;
            letter-spacing: 2px;
          }
          .img {
            display: inline-block;
            width: 74px;
            height: 91px;
            background-size: 100% 100%;
            margin-top: 103px;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.7);
          }
        }
        .item-1 {
          .img {
            background-image: url("../assets/version2/peixun-16.png");
            width: 89px;
            height: 94px;
          }
        }
        .item-2 {
          .img {
            background-image: url("../assets/version2/peixun-14.png");
          }
        }
        .item-3 {
          .img {
            background-image: url("../assets/version2/peixun-19.png");
          }
        }
        &:after {
          content: "";
          width: 340px;
        }
      }

      // 青少年
      .block-6 {
        .item {
          background-image: url("../assets/version2/peixun-24.png");
        }
      }
    }
  }
}
.en-home-wrap {
  .block-4 {
    .class-item-list {
      .item {
        font-size: 20px !important;
        padding: 0px 35px !important;
      }
      .item-3 {
        .btn {
          width: 280px !important;
          font-size: 18px !important;
        }
      }
    }
  }
  .instruments-item-list {
    .item {
      .item-text {
        display: inline-block !important;
        width: auto !important;
        font-size: 30px !important;
        line-height: 236px !important;
      }
    }
  }
}
</style>
